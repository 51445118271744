
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import Button from "../../Button/src/Button.vue";
import UiInput from "../../Input";
import UiButton from "@/components/UI/Button";
import Toast from "@/components/UI/Toast/";
import axios from "@/api/axios";
export default defineComponent({
  name: "Code",
  components: {
    UiInput,
    UiButton,
  },
  props: {
    label: {
      type: String,
      default: "验证码",
    },
    tel: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    validate: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: "获取验证码",
    },
    placeholder: {
      type: String,
      default: "请输入验证码",
    },
  },
  setup(props, ctx) {
    const code = ref("");
    function handleInput(e: any) {
      code.value = code.value.slice(0, 6);
      ctx.emit("update:modelValue", code.value);
    }

    let sendCodeFlag = true;
    let count = 60;
    const codeText = ref(props.btnText);
    let timer = 0;
    function checkTel(tel: string) {
      if (tel === "") {
        Toast({
          title: "请输入手机号码",
          type: "warning",
        });
        return false;
      }
      const retel = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
      if (tel.length != 11 || !retel.test(tel)) {
        Toast({
          title: "请输入正确的手机号码",
          type: "warning",
        });
        // $('#tel').focus();
        return false;
      }
      return true;
    }

    function clearCode() {
      code.value = "";
      sendCodeFlag = true;
      count = 60;
      clearInterval(timer);
      timer = 0;

      codeText.value = "获取验证码";
    }

    //获取验证码
    function getSMSCode() {
      if (!checkTel(props.tel) || !sendCodeFlag) {
        return false;
      }

      sendCodeFlag = false;
      /**
       *  获取验证码
       * */
      const formData = new FormData();
      formData.append("tel", props.tel);
      formData.append("option", "getcode");
      axios
        .post("/M/Server/GetNewSMSCode", formData)
        .then((res) => {
          if (res.data.success) {
            Toast({
              title: "验证码发送成功，请注意查收",
              type: "success",
            });
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //     title: '未知错误，请联系客服',
          //     type: "success"
          // })
        });

      timer = setInterval(function () {
        if (count == 0) {
          clearInterval(timer);
          clearCode();
          sendCodeFlag = true;
          codeText.value = "获取验证码";
        } else {
          codeText.value = count + "s";
        }
        count--;
      }, 1000);
    }

    return {
      handleInput,
      codeText,
      code,
      getSMSCode,
      clearCode,
    };
  },
});
